import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface DataTieringConfig {
    readonly index_lifetime_min: string;
    readonly index_lifetime_max: string;
    readonly type: string;
}
interface RuleBuilderStep {
    readonly step_title: string;
    readonly negate: boolean;
    readonly function: string;
    readonly id: string;
    readonly params: {
        readonly [_key: string]: Object;
    };
    readonly outputvariable: string;
    readonly conditions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface RuleBuilderSimulatorResponse {
    readonly processing_time: string;
    readonly source_input_id: string;
    readonly sequence_nr: number;
    readonly fields_entries: unknown[];
    readonly field_names: string[];
    readonly journal_offset: number;
    readonly source: string;
    readonly inet_address: string;
    readonly simulator_action_variables: unknown[];
    readonly simulator_condition_variables: {
        readonly [_key: string]: Object;
    };
    readonly index_sets: IndexSet[];
    readonly id: string;
    readonly timestamp: string;
    readonly streams: Stream[];
    readonly is_source_inet_address: boolean;
    readonly receive_time: string;
    readonly message_id: string;
    readonly message: string;
    readonly message_queue_id: Object;
    readonly filter_out: boolean;
    readonly size: number;
    readonly stream_ids: string[];
    readonly field_count: number;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly complete: boolean;
}
interface StreamRule {
    readonly field: string;
    readonly stream_id: string;
    readonly description: string;
    readonly id: string;
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly inverted: boolean;
    readonly type: 'EXACT' | 'REGEX' | 'GREATER' | 'SMALLER' | 'PRESENCE' | 'CONTAINS' | 'ALWAYS_MATCH' | 'MATCH_INPUT';
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly content_pack: string;
    readonly value: string;
}
interface IndexSetConfig {
    readonly index_template_name: string;
    readonly index_optimization_max_num_segments: number;
    readonly replicas: number;
    readonly field_type_refresh_interval: string;
    readonly description: string;
    readonly index_optimization_disabled: boolean;
    readonly creation_date: string;
    readonly field_type_profile: string;
    readonly title: string;
    readonly index_wildcard: string;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly writable: boolean;
    readonly shards: number;
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly index_analyzer: string;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly index_template_type: string;
    readonly rotation_strategy_class: string;
    readonly id: string;
    readonly index_prefix: string;
    readonly regular: boolean;
    readonly data_tiering: DataTieringConfig;
    readonly index_match_pattern: string;
}
interface RotationStrategyConfig {
    readonly type: string;
}
type anyArray = unknown[];
interface CustomFieldMapping {
    readonly field: string;
    readonly type: string;
}
interface SimulateRuleBuilderRequest {
    readonly message: string;
    readonly rule_builder_dto: RuleBuilderDto;
}
interface RuleBuilderDto {
    readonly simulator_message: string;
    readonly rule_builder: RuleBuilder;
    readonly description: string;
    readonly created_at: string;
    readonly id: string;
    readonly source: string;
    readonly title: string;
    readonly modified_at: string;
}
interface Output {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly content_pack: string;
}
interface Object {
}
interface Stream {
    readonly outputs: Output[];
    readonly paused: boolean;
    readonly description: string;
    readonly title: string;
    readonly stream_rules: StreamRule[];
    readonly content_pack: string;
    readonly index_set_id: string;
    readonly index_set: IndexSet;
    readonly matching_type: 'AND' | 'OR';
    readonly remove_matches_from_default_stream: boolean;
    readonly disabled: boolean;
    readonly categories: string[];
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly default_stream: boolean;
}
interface RuleBuilder {
    readonly conditions: RuleBuilderStep[];
    readonly actions: RuleBuilderStep[];
    readonly operator: 'AND' | 'OR';
    readonly errors: string[];
}
interface Validator {
}
interface IndexSet {
    readonly write_index_alias: string;
    readonly managed_indices: string[];
    readonly active_write_index: string;
    readonly all_index_aliases: {
        readonly [_key: string]: string[];
    };
    readonly up: boolean;
    readonly index_wildcard: string;
    readonly index_prefix: string;
    readonly newest_index: string;
    readonly config: IndexSetConfig;
}
/**
 * Create a processing rule from rule builder
 */
export function createFromBuilder(rule: RuleBuilderDto): Promise<RuleBuilderDto> {
    return __request__('POST', '/system/pipelines/rulebuilder', rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get action descriptors for rule builder
 */
export function actions(): Promise<anyArray> {
    return __request__('GET', '/system/pipelines/rulebuilder/actions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get condition descriptors for ruleBuilder
 */
export function conditions(): Promise<anyArray> {
    return __request__('GET', '/system/pipelines/rulebuilder/conditions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Simulate a single processing rule created by the rule builder
 */
export function simulate(rule: SimulateRuleBuilderRequest): Promise<RuleBuilderSimulatorResponse> {
    return __request__('POST', '/system/pipelines/rulebuilder/simulate', rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate rule builder
 */
export function validate(rule: RuleBuilderDto): Promise<RuleBuilderDto> {
    return __request__('POST', '/system/pipelines/rulebuilder/validate', rule, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a processing rule from rule builder
 */
export function updateFromBuilder(rule: RuleBuilderDto, id?: string): Promise<RuleBuilderDto> {
    return __request__('PUT', `/system/pipelines/rulebuilder/${id}`, rule, {}, {
        'Accept': ['application/json']
    });
}
