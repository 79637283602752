import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface IndexSetTemplateConfig {
    readonly shards: number;
    readonly index_optimization_max_num_segments: number;
    readonly index_analyzer: string;
    readonly replicas: number;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly use_legacy_rotation: boolean;
    readonly field_type_refresh_interval: string;
    readonly index_optimization_disabled: boolean;
    readonly rotation_strategy_class: string;
    readonly retention_strategy_class: string;
    readonly data_tiering: DataTieringConfig;
    readonly retention_strategy: RetentionStrategyConfig;
}
interface DataTieringConfig {
    readonly index_lifetime_min: string;
    readonly index_lifetime_max: string;
    readonly type: string;
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface RotationStrategyConfig {
    readonly type: string;
}
interface IndexSetTemplateRequest {
    readonly index_set_config: IndexSetTemplateConfig;
    readonly description: string;
    readonly title: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
type IndexSetTemplateResponseArray = IndexSetTemplateResponse[];
interface IndexSetTemplateResponse {
    readonly default: boolean;
    readonly index_set_config: IndexSetTemplateConfig;
    readonly built_in: boolean;
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly disabled_reason: string;
    readonly enabled: boolean;
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface PageListResponse_IndexSetTemplateResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: IndexSetTemplateResponse[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
type stringArray = string[];
/**
 * Creates a new editable template
 */
export function create(request?: IndexSetTemplateRequest): Promise<IndexSetTemplateResponse> {
    return __request__('POST', '/system/indices/index_sets/templates', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets built-in templates
 */
export function builtIns(warm_tier_enabled?: boolean): Promise<IndexSetTemplateResponseArray> {
    return __request__('GET', '/system/indices/index_sets/templates/built-in', null, { 'warm_tier_enabled': warm_tier_enabled }, {
        'Accept': ['application/json']
    });
}
/**
 * Gets default template
 */
export function getDefaultConfig(): Promise<IndexSetTemplateConfig> {
    return __request__('GET', '/system/indices/index_sets/templates/default_config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets template by id
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'name' | 'title' = 'title', page: number = 1, per_page: number = 50, query?: string, filters?: stringArray, order: 'asc' | ' desc' = 'asc'): Promise<PageListResponse_IndexSetTemplateResponse> {
    return __request__('GET', '/system/indices/index_sets/templates/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Updates existing template
 */
export function update(id: string, request?: IndexSetTemplateRequest): Promise<void> {
    return __request__('PUT', `/system/indices/index_sets/templates/${id}`, request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets template by id
 */
export function retrieveById(template_id?: string): Promise<IndexSetTemplateResponse> {
    return __request__('GET', `/system/indices/index_sets/templates/${template_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Removes a template
 */
export function remove(template_id?: string): Promise<void> {
    return __request__('DELETE', `/system/indices/index_sets/templates/${template_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
