import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface NotificationDto {
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: EventNotificationConfig;
}
interface EntityAttribute {
    readonly related_property: string;
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PaginatedResponse_NotificationDto {
    readonly [_key: string]: Object;
}
interface EventNotificationConfig {
    readonly type: string;
}
interface Object {
}
interface PageListResponse_NotificationDto {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: NotificationDto[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
/**
 * List all available notifications
 */
export function listNotifications(page: number = 1, per_page: number = 50, query?: string): Promise<PaginatedResponse_NotificationDto> {
    return __request__('GET', '/events/notifications', null, { 'page': page, 'per_page': per_page, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Create new notification definition
 */
export function create(JSONBody?: NotificationDto): Promise<unknown> {
    return __request__('POST', '/events/notifications', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all available legacy alarm callback types
 */
export function legacyTypes(): Promise<unknown> {
    return __request__('GET', '/events/notifications/legacy/types', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of event notifications
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'type' = 'title', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse_NotificationDto> {
    return __request__('GET', '/events/notifications/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Send a test alert for a given event notification
 */
export function test(JSONBody?: NotificationDto): Promise<unknown> {
    return __request__('POST', '/events/notifications/test', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a notification
 */
export function get(notificationId?: string): Promise<NotificationDto> {
    return __request__('GET', `/events/notifications/${notificationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update existing notification
 */
export function update(notificationId?: string, JSONBody?: NotificationDto): Promise<unknown> {
    return __request__('PUT', `/events/notifications/${notificationId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a notification
 */
export function remove(notificationId?: string): Promise<void> {
    return __request__('DELETE', `/events/notifications/${notificationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Send a test alert for a given event notification
 * @param notificationId The event notification id to send a test alert for.
 */
export function testBynotificationId(notificationId: string): Promise<unknown> {
    return __request__('POST', `/events/notifications/${notificationId}/test`, null, {}, {
        'Accept': ['application/json']
    });
}
